<template>
  <div>
    <el-dialog v-dialogDrag title="选择事件模板" append-to-body :visible.sync="visible" width="900px">
      <el-input placeholder="请输入模板名称" @keyup.enter.native="search" v-model="name">
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-input>
      <div class="flex">
        <div class="left">
          <div class="title">事件类型</div>
          <div class="content">
            <ul class="eventType">
              <li @click="clickType(null)" :class="!typeId?'active':''">全部事件类型</li>
              <li @click="clickType(item.id)" :title="item.typeName" :class="typeId==item.id?'active':''" class="tag" size="mini" v-for="item in typeList" :key="item.id">
                {{item.typeName}}
              </li>
            </ul>
          </div>
        </div>
        <div class="mid">
          <div class="title">事件模板</div>
          <div class="content" v-loading="loading" style="padding:0">
            <!-- <el-button  icon="el-icon-plus" class="tag" size="mini" v-for="item in list" :key="item.id">{{item.tempName}}</el-button> -->
            <el-scrollbar style="height:100%">
              <el-radio-group v-model="radio" size="mini" class="radio-group">
                <div v-for="item in list" :key="item.id" class="radio">
                  <el-radio @click.native="choose(item)" :label="item.id">{{item.tempName}}</el-radio>
                </div>
              </el-radio-group>
            </el-scrollbar>
          </div>
        </div>
        <div class="right">
          <div class="title">已选择</div>
          <div class="content">
            <el-tag class="tag ellipsis" v-show="chooseItem.tempName" type="primary" size="medium" @close="remove" closable>{{chooseItem.tempName}}</el-tag>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
  },
  data() {
    return {
      typeList: [],
      list: [],
      loading: false,
      chooseItem: {},
      name: null,
      typeId: "",
      radio: "",
    };
  },
  created() {
    this.getType();
    this.getList();
  },
  methods: {
    search() {
      this.chooseItem = {};
      this.getList();
    },
    //删除选择的模板
    remove() {
      this.chooseItem = {};
      this.radio = "";
    },
    // 确认
    confirm() {
      this.$emit("update:show", false);
      this.$emit("confirm", this.chooseItem);
      console.log(this.chooseItem);
    },
    // 选择事件
    choose(item) {
      this.chooseItem = item;
      console.log(this.radio);
    },
    //点击左边类型,查询事件模板
    clickType(id) {
      this.typeId = id;

      this.getList();
    },
    // 获取类型
    async getType() {
      let res = await this.$get("/platform-config/templatetype/page");
      if (res && res.code == 0) {
        this.typeList = res.data.list;
      }
    },
    // 获取列表
    async getList() {
      this.loading = true;
      var data = {
        page: 1,
        limit: 99,
        name: this.name,
      };
      if (this.typeId) data.templateTypeId = this.typeId;
      let res = await this.$get("/platform-config/templateinfo/page", data);
      this.loading = false;
      if (res && res.code == 0) {
        this.list = res.data.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped >
/deep/ .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
.flex {
  display: flex;
  min-height: 500px;
  border: 1px solid #eee;
  margin-top: 20px;
  > div {
    flex: 1;
    .title {
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #eee;
      text-align: center;
    }
    .tag {
      margin: 0 5px 5px 0;
      display: block;
      /deep/ .el-tag__close {
        float: right;
        top:5px
      }
    }
    .content {
      padding: 20px;
      margin: 0 0 0 0;
      height: calc(100% - 36px);
      overflow-y: auto;
    }
    &.left {
      border-right: 1px solid #eee;
      .eventType li {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #eee;
        margin-bottom: 0;
        padding: 0 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &.active {
          background: #dfefff;
        }
      }
    }
    &.mid {
      border-right: 1px solid #eee;
      .radio-group {
        width: 100%;
        padding: 20px;
      }
      .radio {
        padding: 5px;
        border-bottom: 1px solid #eee;
        margin-bottom: 0;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
