<template>
  <div>
    <el-page-header @back="$router.back()" content="账户详情">
    </el-page-header>
    <el-form v-loading="selLoading" style="padding:20px;" label-width="160px" disabled size="small" inline class="form"
      ref="form">
     <!-- <el-form-item label="账户类型:" prop="accountType">
        <span v-if="form.accountType === 0">企业账户 </span>
        <span v-if="form.accountType === 1">企业账户 </span>
      </el-form-item> -->
      <el-form-item label="用户类型:" prop="userType">
        <span v-if="form.userType === 0">付费 </span>
        <span v-if="form.userType === 1">试用 </span>
        <span v-if="form.userType === 2">第三方渠道 </span>
      </el-form-item>
     <!-- <el-form-item label="组织类型:" prop="organizationType">
        <span v-if="form.organizationType === 0">社区组织</span>
        <span v-if="form.organizationType === 1">企业组织</span>
      </el-form-item> -->
      <el-form-item label="公司名称:" prop="companyName">
        {{ form.companyName }}
      </el-form-item>
      <el-form-item label="公司简称:" prop="companyAbbreviation">
        {{ form.companyAbbreviation }}
      </el-form-item>
      <el-form-item label="企业编号:" prop="tenantCode">
        {{ form.tenantCode }}
      </el-form-item>
      <el-form-item label="社会统一编码:" prop="socialUniformCoding">
        {{ form.socialUniformCoding }}
      </el-form-item>
      <div>
        <el-form-item label="企业logo:" prop="companyLogo">
          <img width="50px" v-if="form.companyLogo" :src="form.companyLogo" alt="">
        </el-form-item>
        <el-form-item label="证件图片:" prop="certificateImages">
          <img width="50px" v-if="form.certificateImages" :src="form.certificateImages" alt="">
        </el-form-item>
      </div>
      <el-form-item label="管理员账户:" prop="username">{{ form.username }}</el-form-item>
      <el-form-item label="所属行业:" prop="industrysId">{{ form.industryName }}</el-form-item>
      <el-form-item label="经营类型:" prop="industrysManageId">{{ form.industryManageName }}</el-form-item>
      <el-form-item label="企业负责人:" prop="enterprisesOwners">{{ form.enterprisesOwners }}</el-form-item>
      <el-form-item label="职务:" prop="duty">{{ form.duty }}</el-form-item>
      <el-form-item label="负责人电话:" prop="principalTelephone">{{ form.principalTelephone }}</el-form-item>
      <el-form-item label="所在地区:" prop="areaId">{{ form.areaName }}</el-form-item>
      <el-form-item label="地址:" prop="address"> {{ form.address }}</el-form-item>
      <el-form-item label="软件对接人:" prop="softwareConnector">{{ form.softwareConnector }}</el-form-item>
      <el-form-item label="对接人电话:" prop="receiverTelephone">{{ form.receiverTelephone }}</el-form-item>
      <el-form-item label="购买人数包:" prop="purchasersNumber">{{ form.purchasersNumber }}</el-form-item>
      <el-form-item label="存储空间配额:" prop="storageSpaceQuota">{{ form.storageSpaceQuota }}</el-form-item>
      <el-form-item label="启动日期:" prop="startDate">{{ form.startDate }}</el-form-item>
      <el-form-item label="到期日期:" prop="expiryDate">{{ form.expiryDate }}</el-form-item>

      <el-form-item label="开单人:" prop="templateId">{{ form.biller }}</el-form-item>

      <el-form-item label="创建人:" prop="templateId">{{ form.createName }}</el-form-item>
      <br />
      <el-form-item label="功能模块:" prop="moduleIds" class="module">{{ form.modulesName }}</el-form-item>
      <br />
      <el-form-item label="积分类型:" prop="pointsType">
        <span v-if="form.pointsType === 0">B分</span>
        <span v-if="form.pointsType === 1">B分+A分</span>
        <span v-if="form.pointsType === 2">B分+A分+产值</span>
      </el-form-item>
      <br />
      <el-form-item label="开启超标事件:">
        <el-switch v-model="form.isExceedNormIncident" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启临时事件:">
        <el-switch v-model="form.isTempIncident" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启创建打印队列:">
        <el-switch v-model="form.isPrintQueue" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启分享邀请:">
        <el-switch v-model="form.isShareUser" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启app考勤模块:">
        <el-switch v-model="form.isAppWorkAttendance" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="开启临时事件奖票:">
        <el-switch v-model="form.isTempTicket" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="不显示默认轮播图:">
        <el-switch v-model="form.isNotDefaultSlideshow" :active-value="1" :inactive-value="0" active-color="#13ce66"
          inactive-color="#f4f4f4">
        </el-switch>
      </el-form-item>
      <br />
      <el-form-item label="状态:">
        <span v-if="form.status === 0">冻结</span>
        <span v-if="form.status === 1">正常</span>
      </el-form-item>
      <br />
      <el-form-item label="事件模板库:" prop="templateId">{{ form.tempName }}</el-form-item>


    </el-form>
  </div>
</template>

<script>
import ChooseEvent from "./chooseEvent";

export default {
  components: {
    ChooseEvent,
  },

  data() {
    return {
      form: {},
      selLoading: false,
      id: "",
      // 地区选项
      area: [],
      // 行业选项-1级
      industry: [],
      // 行业选项-2级
      industrysManage: [],

      // 选择的地区ids
      areaIds: [],
    };
  },
  watch: {
    id(val) {
      console.log("change");
      this.sel(val);
    },
  },

  computed: {},

  created() {
    this.getIndustry();
    this.getModule();
    this.getArea();
    this.id = this.$route.query.id;
  },
  mounted() {
  },


  methods: {
    //查询详情
    async sel() {
      this.selLoading = true;
      var res = await this.$get("/platform-admin/community/" + this.id);
      this.selLoading = false;
      if (res && res.code === 0) {
        this.form = res.data;
        // 设置数据回显
        this.setArea(this.area); // 地区
        this.setIndustry(this.industry); //行业
        this.setModules(this.modules); //模块

      }
    },
    // 设置地区回显
    setArea(arr) {
      var ids = [];
      console.log(this.form);
      if (this.form.provinceId) ids.push(this.form.provinceId);
      if (this.form.cityId) ids.push(this.form.cityId);
      if (this.form.areaId) ids.push(this.form.areaId);
      if (!arr.length || !ids.length) return;
      var list = [];

      arr.map((item) => {
        if (item.id === ids[0]) {
          list[0] = item.name;
        }
        if (item.id === ids[1]) {
          list[1] = item.name;
        }
        if (item.id === ids[2]) {
          list[2] = item.name;
        }
      });
      console.log("areaList", list);
      this.form.areaName = list.join();
      this.$forceUpdate();
    },
    // 设置 行业回显
    setIndustry(arr) {
      if (!arr || !arr.length || !this.form.industrysId) return;

      var newArr = arr.filter((item) => {

        return item.id === this.form.industrysId;
      });
      if (newArr && newArr.length) {
        this.form.industryName = newArr[0].industryName;
        this.setIndustryManage(newArr[0].children);
      }
      console.log("industryName", this.form.industryName);
      this.$forceUpdate();
    },
    // 设置 经营类型回显
    setIndustryManage(arr) {
      if (!arr || !arr.length || !this.form.industrysManageId) return;

      var newArr = arr.filter((item) => {
        return item.id === this.form.industrysManageId;
      });

      if (newArr && newArr.length) {
        this.form.industryManageName = newArr[0].industryName;
      }
      console.log("industryManageName", this.form.industryManageName);
      this.$forceUpdate();
    },
    // 设置 模块回显
    setModules(arr) {
      if (!arr || !arr.length || !this.form.moduleIds) return;
      if (!this.form.moduleIds.length) return;
      var list = [];
      this.form.moduleIds.map((x) => {
        arr.map((c) => {
          if (x === c.id) list.push(c.moduleName);
        });
      });
      this.form.modulesName = list.join();
      this.$forceUpdate();
    },
    // 获取地区选项
    async getArea() {
      var res = await this.$get("/platform-admin/region/tree");
      if (res && res.code === 0) {
        this.area = res.data;
        this.setArea(this.area);
      }
    },
    // 获取行业选项
    async getIndustry() {
      var res = await this.$get("/platform-config/industry/tree");
      if (res && res.code === 0) {
        this.industry = res.data;
        this.setIndustry(this.industry);
      }
    },
    // 获取应用模块
    async getModule() {
      var res = await this.$get("/platform-config/modulemanagement/page", {
        page: 1,
        limit: 999,
      });
      if (res && res.code === 0) {
        this.modules = res.data.list;
        this.setModules(this.modules);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item {
  min-width: 420px;

  .el-form-item__content {
    color: #409eff;
  }
}
</style>
